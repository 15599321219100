import { graphql, useStaticQuery } from 'gatsby'

export default function () {
  return useStaticQuery(graphql`
    query CompaniesContextContentDK {
      companies: allContentfulCompany(
        filter: { market: { value: { eq: "DK" } } }
      ) {
        edges {
          node {
            id
            slug
            jobTypes {
              name
              type
              value
            }
            norway
            collectiveAgreement
            authorizedBemanning
            logo {
              gatsbyImageData(width: 350, placeholder: NONE)
            }
            name
            numberOfEmployees
            locations {
              name
            }
            professions {
              value
            }
            isPro: pro
            disableExtraApplications
            allowEmployerApplications
            employerId
          }
        }
      }
      premiumPlacements: allContentfulPremiumPlacement(
        filter: { isLive: { eq: true } }
      ) {
        totalCount
        edges {
          node {
            percentageOfTraffic
            buttonColor
            backgroundColor
            redirectUrl
            company {
              slug
              norway
            }
          }
        }
      }
    }
  `)
}
