import { localeForMarket } from '@bemlo/utils'
import { Market } from '@bemlo/enums'

import { MarketFeature } from '../enums'
import { MarketConfig } from '../types'
import translations from '../../translations/locales/da'

import { baseMarketMetadata } from './base-meta'
import { Country, Currency } from '../../generated/graphql'

// Cannot use getTranslation here, becuase this is used outside the scope of Webpack
const t = translations.meta

export const marketConfig: MarketConfig = {
  market: Market.DK,
  locale: localeForMarket(Market.DK),
  localeName: 'da',
  country: {
    currency: Currency.DKK,
    name: 'Denmark',
    value: Country.DENMARK,
    wikidataCode: 'Q35',
  },
  features: [
    MarketFeature.ABOUT_US,
    MarketFeature.BLOG,
    MarketFeature.COMPANY,
    MarketFeature.LANDING_PAGE,
    MarketFeature.LEADS,
  ],
  meta: {
    ...baseMarketMetadata,
    companyNamePossessive: t.company_name_possessive,
    siteDescription: t.site_description,
    siteKeywords: t.site_keywords,
    siteNamePossessive: t.site_name_possessive,
    email: 'info@bemlo.dk',
    emailDomain: 'bemlo.dk',
    siteCareerUrl: 'https://career.bemlo.se',
    siteDomain: 'bemlo.dk',
    siteLocale: 'da_DK',
    siteUrl: 'https://www.bemlo.dk',
  },
  segmentProdKey: '7UBuvsHyQYqtrb3M6ELeVuYo3dbtSuRh',
  reviewsFrom: [Market.DK],
}
